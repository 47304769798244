<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Google Configuration</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="adminForm"
            >
              <form
                  class="emails-form"
                  @submit.prevent="handleSubmit(updateAPIKey)"
              >
                <label>API Key</label>
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                        v-if="isDisabled"
                        v-model="formattedPassword"
                        disabled
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <TextInput
                        v-else
                        v-model="user.settings.google_api_key"
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                      <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                      <Feather v-else type="x" class="icon-lg" />
                    </button>
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="isBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">OpenAi Configuration</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="adminForm"
            >
              <form
                  class="emails-form"
                  @submit.prevent="handleSubmit(updateOpenAiAPIKey)"
              >
                <label>API Key</label>
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                        v-if="aiDisabled"
                        v-model="formattedAIPassword"
                        disabled
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <TextInput
                        v-else
                        v-model="user.settings.openai_api_key"
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <button type="button" class="btn btn-link p-0" @click="aiDisabled = !aiDisabled">
                      <Feather v-if="aiDisabled" type="edit" class="icon-lg" />
                      <Feather v-else type="x" class="icon-lg" />
                    </button>
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="aiBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="aiBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Trial Banner</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="bannerForm"
            >
              <form
                  class="banner-form"
                  @submit.prevent="handleSubmit(updateBannerText)"
              >
                <label>Banner Text <small>($$trial_end_date$$ and $$trial_days_left$$ will be replaced with users trial data)</small></label>
                <div class="row">
                  <div class="col">
                    <TextInput
                        v-model="user.settings.misc.banner_text"
                    />
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="isBannerBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isBannerBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Reply Prompt</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="bannerForm"
            >
              <form
                  class="banner-form"
                  @submit.prevent="handleSubmit(updateReplyPrompt)"
              >
                <label>Prompt</label>
                <div class="row">
                  <div class="col">
                    <textarea v-model="user.settings.misc.reply_prompt" class="form-control" rows="5" />
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="isPromptBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isPromptBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      isBusy:false,
      aiBusy:false,
      aiDisabled:true,
      isBannerBusy:false,
      isDisabled:true,
      isPromptBusy:false,
      isDashboardPlanBusy:false,
      user:{
        settings:{
          misc:{
            dashboard_reply_engine_plan:'',
            dashboard_tap_engine_plan:''
          }
        }
      }
    }
  },

  computed: {
    formattedPassword:{
      get(){
        return '*'.repeat(this.user.settings.google_api_key?.length - 7) + this.user.settings.google_api_key?.slice(-7);
      },
      set(value){
      }
    },
    formattedAIPassword:{
      get(){
        return '*'.repeat(this.user.settings.openai_api_key?.length - 7) + this.user.settings.openai_api_key?.slice(-7);
      },
      set(value){
      }
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
  },

  methods: {
    updateBannerText(){
      this.isBannerBusy = true

      let params = {
        banner_text:this.user.settings.misc.banner_text,
        admin_setting_id:this.user.settings.id,
        setting_form:'banner_settings'
      }

      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.isBannerBusy = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.isBannerBusy = false
          })
    },
    updateOpenAiAPIKey(){
      this.aiBusy = true

      let params = {
        openai_api_key: this.user.settings.openai_api_key,
        admin_setting_id: this.user.settings.id,
        setting_form: 'admin_settings'
      }

      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.aiBusy = false
            this.aiDisabled = true
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.aiBusy = false
          })
    },
    updateReplyPrompt(){
      this.isPromptBusy = true

      let params = {
        reply_prompt: this.user.settings.misc.reply_prompt,
        admin_setting_id: this.user.settings.id,
        setting_form: 'admin_settings'
      }
      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.isPromptBusy = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.isPromptBusy = false
          })
    },
    updateAPIKey() {
      this.isBusy = true

      let params = {
        google_api_key: this.user.settings.google_api_key,
        admin_setting_id: this.user.settings.id,
        setting_form: 'admin_settings'
      }

      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.isBusy = false
            this.isDisabled = true
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    },
  },
}
</script>
